<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <v-divider></v-divider>
          <base-hover-button
            class="my-2"
            color="primary lighten-5"
            text-color="primary--text"
            :text="$t('general.back')"
            @click.native="goBack()"
            iconName=" mdi-arrow-left-circle"
          />
          <v-divider dark></v-divider>
          <!--Status-->
          <v-card v-if="o_Fields.status" outlined class="d-flex justify-space-between pa-1 my-1">
            <div class="d-flex flex-row" style="min-width: 25%">
              <v-select
                class="pt-3"
                dense
                :label="$t('general.status')"
                item-text="s_Name"
                item-value="i_ID"
                :items="La_Status"
                v-model="Lo_Question.i_Status"
                @change="$v.Lo_Question.i_Status.$touch()"
                @blur="$v.Lo_Question.i_Status.$touch()"
                :error-messages="StatusErrors"
              ></v-select>
              <v-tooltip bottom>
                <!--                 <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Info...</span> -->
              </v-tooltip>
            </div>
          </v-card>
          <!--End--Status-->
          <!--Author-->
          <v-card v-if="o_Fields.author" outlined class="d-flex pa-1 my-1">
            <div class="d-flex flex-row" style="min-width: 25%">
              <v-select
              item-text="s_Name"
              item-value="AddressID"
              v-model="La_selectedAuthors"
              :items="La_Authors"
              :label="$t('general.author')"
              multiple
              @change="$v.La_selectedAuthors.$touch()"
              @blur="$v.La_selectedAuthors.$touch()"
              :error-messages="AuthorsErrors"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle"
                  >
                    <v-list-item-action>
                      <v-icon :color="La_selectedAuthors.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{$t('general.select_all')}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </div>
            <div class=""></div>
          </v-card>
          <!--End--Author-->
          <!--Language-->
          <v-card v-if="o_Fields.language" outlined class="d-flex justify-space-between pa-1 my-1">
            <div class="d-flex flex-row" style="min-width: 25%">
              <v-select
                class="pt-3"
                dense
                :label="$t('general.language')"
                :items="La_Lang"
                v-model="Lo_Question.s_Language"
                @change="$v.Lo_Question.s_Language.$touch()"
                @blur="$v.Lo_Question.s_Language.$touch()"
                :error-messages="LanguageErrors"
              ></v-select>
              <v-tooltip bottom>
                <!--                 <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Info...</span> -->
              </v-tooltip>
            </div>
          </v-card>
          <!--End--Language-->
          <!--Vertiefungsmodul-->
          <v-card v-if="o_Fields.advancedModule" outlined class="d-flex justify-space-between pa-1 my-1">
            <div class="d-flex flex-row" style="min-width: 25%">
              <v-select
                class="pt-3"
                dense
                :items="$t('general.a_AdvancedModule')"
                item-text="s_Name"
                item-value="s_Shortname"
                v-model="Lo_Question.s_AdvancedModule"
                :label="$t('general.advanced_module')"
                @change="$v.Lo_Question.s_AdvancedModule.$touch()"
                @blur="$v.Lo_Question.s_AdvancedModule.$touch()"
                :error-messages="AdvancedModuleErrors"
              ></v-select>
              <v-tooltip bottom>
                <!--                 <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template> 
                <span>Info...</span>-->
              </v-tooltip>
            </div>
          </v-card>
          <!--End-Vertiefungsmodul-->

          <!--Wahlmodul-->
          <v-card v-if="o_Fields.electiveModule" outlined class="d-flex justify-space-between pa-1 my-1">
            <div class="d-flex flex-row" style="min-width: 25%">
              <v-select
                class="pt-3"
                dense
                :items="$t('general.a_ElectiveModule')"
                item-text="s_Name"
                item-value="s_Shortname"
                v-model="Lo_Question.s_ElectiveModule"
                :label="$t('general.elective_module')"
                @change="$v.Lo_Question.s_ElectiveModule.$touch()"
                @blur="$v.Lo_Question.s_ElectiveModule.$touch()"
                :error-messages="ElectiveModuleErrors"
              ></v-select>
              <v-tooltip bottom>
                <!--                 <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Info...</span> -->
              </v-tooltip>
            </div>
          </v-card>
          <!--End-Wahlmodul-->

          <!--Tags-->
          <v-card v-if="o_Fields.tag" outlined class="d-flex justify-space-between pa-1 my-1">
            <div class="d-flex flex-row" style="min-width: 25%">
              <v-select
                class="pt-3"
                dense
                item-value="s_Id"
                item-text="s_Text"
                :items="$t('a_Tags')"
                v-model="Lo_Question.s_Tag"
                :label="$t('general.tag')"
                @change="$v.Lo_Question.s_Tag.$touch()"
                @blur="$v.Lo_Question.s_Tag.$touch()"
                :error-messages="TagErrors"
              ></v-select>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("info.tag") }}</span>
              </v-tooltip>
            </div>
          </v-card>
          <!--End-Tags-->
          <!--Designation-->
          <v-card v-if="o_Fields.designation" outlined class="px-1 my-1">
            <v-row >
              <v-col class="py-0" cols="12" xs="4" sm="7" md="8" lg="6" xl="4">
                <v-text-field
                  :counter="50"
                  v-model="DesignationLength"
                  :label="$t('general.short_description')"
                  required
                  clearable
                  @input="$v.Lo_Question.s_Designation.$touch()"
                  @blur="$v.Lo_Question.s_Designation.$touch()"
                  :error-messages="DesignationErrors"
                />
              </v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("info.designation") }}</span>
              </v-tooltip>
            </v-row >
          </v-card>
          <!--End--Designation-->
          <!--Beschreibung-->
          <div v-if="o_Fields.description">
            <v-card
              outlined
              :color="DescriptionErrors ? 'red--text' : ''"
              class="pa-1 my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.description") }}:
                  </h6>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mb-3"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("info.description") }}</span>
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editDescription">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editDescription = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editDescription">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editDescription = false),
                          $v.Lo_Question.s_Description.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div v-if="!editDescription" v-html="Lo_Question.s_Description" />
              <div
                v-if="DescriptionErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>

              <Tiptap
                v-if="editDescription"
                v-model="Lo_Question.s_Description"
              />
            </v-card>
          </div>
          <!--End--Beschreibung-->
          <!--Rolle der Kandidatin-->
          <div v-if="Pi_TestTypeID == 1 && o_Fields.role">
            <v-card
              outlined
              :color="CandidateRoleErrors ? 'red--text' : ''"
              class="pa-1 my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.role_of_the_candidate") }}:
                  </h6>
                  <v-tooltip bottom>
                    <!--                     <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mb-3"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Info...</span> -->
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editRolle">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editRolle = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editRolle">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editRolle = false),
                          $v.Lo_Question.s_CandidateRole.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div v-if="!editRolle" v-html="Lo_Question.s_CandidateRole" />
              <div
                v-if="CandidateRoleErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>
              <Tiptap v-if="editRolle" v-model="Lo_Question.s_CandidateRole" />
            </v-card>
          </div>
          <!--End--Rolle der Kandidatin-->
          <!--InitialSituation-->
          <div v-if="o_Fields.initialSituation">
            <v-card
              outlined
              :color="InitialSituationErrors ? 'red--text' : ''"
              class="pa-1 my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.initial_situation") }}:
                  </h6>
                  <v-tooltip bottom>
                    <!--                     <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mb-3"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Info...</span> -->
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editAusgang">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editAusgang = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editAusgang">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editAusgang = false),
                          $v.Lo_Question.s_InitialSituation.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="!editAusgang"
                v-html="Lo_Question.s_InitialSituation"
              />

              <div
                v-if="InitialSituationErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>

              <Tiptap
                v-if="editAusgang"
                v-model="Lo_Question.s_InitialSituation"
              />
            </v-card>
          </div>
          <!--End--InitialSituation-->
          <!--Test Presentation-->
          <div v-if="Pi_TestTypeID == 2 && o_Fields.statement">
            <v-card
              outlined
              :color="TestPresentationErrors ? 'red--text' : ''"
              class="pa-1 my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.task_definition") }}:
                  </h6>
                  <v-tooltip bottom>
                    <!--                     <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mb-3"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Info...</span> -->
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editPresentation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editPresentation = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editPresentation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editPresentation = false),
                          $v.Lo_Question.s_TestPresentation.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="!editPresentation"
                v-html="Lo_Question.s_TestPresentation"
              />

              <div
                v-if="TestPresentationErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>

              <Tiptap
                v-if="editPresentation"
                v-model="Lo_Question.s_TestPresentation"
              />
            </v-card>
          </div>
          <!--End Test presentation-->
          <!--Solution Presentation-->
          <div v-if="Pi_TestTypeID == 2 && o_Fields.solution">
            <v-card
              outlined
              :color="SolutionPresentationErrors ? 'red--text' : ''"
              class="pa-1 my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <h6 class="font-weight-bold">
                    {{ $t("general.solution_presentation") }}:
                  </h6>
                  <v-tooltip bottom>
                    <!--                     <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mb-3"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Info...</span> -->
                  </v-tooltip>
                </div>
                <v-tooltip bottom v-if="!editSolutionPresentation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="editSolutionPresentation = true"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.edit") }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="editSolutionPresentation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="success"
                      @click="
                        (editSolutionPresentation = false),
                          $v.Lo_Question.s_SolutionPresentation.$touch()
                      "
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("general.close") }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="!editSolutionPresentation"
                v-html="Lo_Question.s_SolutionPresentation"
              />

              <div
                v-if="SolutionPresentationErrors"
                class="v-messages theme--light error--text"
                role="alert"
              >
                {{ $t("errormessages.is_required") }}
              </div>

              <Tiptap
                v-if="editSolutionPresentation"
                v-model="Lo_Question.s_SolutionPresentation"
              />
            </v-card>
          </div>
          <!--End SolutionPresentation -->
          <v-divider class="my-4" />
          <div class="my-4" />
          <!--Frage an den Kandidaten/an die Kandidatin:-->
          <div v-if="o_Fields.questions && Pi_TestTypeID != 3">
            <div class="d-flex flex-row">
              <h6 class="font-weight-bold">
                {{ $t("general.questions_for_the_candidate") }}:
              </h6>
              <v-tooltip bottom>
                <!--                 <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mb-3"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Info...</span> -->
              </v-tooltip>
            </div>
            <v-alert dense outlined type="error" v-if="QuestionsSizeErrors">
              {{ NumberofMandatoryQuestions }}
              {{ $t("errormessages.questions_must_be_present_as_a_minimum") }}
            </v-alert>
            <v-card
              outlined
              class="pa-1 my-1"
              v-for="(o_Question, index) in Lo_Question.a_Questions"
              :key="index"
              :color="QuestionErrors(index) ? 'red--text' : ''"
            >
              <div class="d-flex justify-space-between">
                <h6>{{ $t("general.question") }} {{ index + 1 }} :</h6>
                <div>
                  <v-tooltip bottom v-if="!editFragen">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="success"
                        @click="editFragen = true"
                      >
                        <v-icon>mdi-pencil-box-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="editFragen">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="success"
                        @click="editFragen = false"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("general.close") }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="Pi_TestTypeID && Pi_TestTypeID != 1" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="danger"
                        @click="dialog = true"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("general.delete") }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div>
                <div
                  v-if="
                    !editFragen ||
                    (Lo_Question.i_TestTypeID && Lo_Question.i_TestTypeID == 1)
                  "
                  class="mb-4"
                  v-html="o_Question.s_Question"
                />
                <div
                  v-if="QuestionErrors(index)"
                  class="v-messages theme--light error--text"
                  role="alert"
                >
                  {{ $t("errormessages.is_required") }}
                </div>
                <Tiptap
                  v-if="editFragen && Pi_TestTypeID != 1"
                  v-model="o_Question.s_Question"
                />
                <h6 v-if="!editFragen">
                  {{ $t("general.answer") }} {{ index + 1 }} :
                </h6>
                <div
                  v-if="!editFragen"
                  class=""
                  v-html="o_Question.s_ExpectedAnswer"
                />
                <h6 v-if="editFragen">
                  {{ $t("general.answer") }} {{ index + 1 }} :
                </h6>
                <div
                  v-if="QuestionErrors(index)"
                  class="v-messages theme--light error--text"
                  role="alert"
                >
                  {{ $t("errormessages.is_required") }}
                </div>
                <Tiptap
                  v-if="editFragen"
                  v-model="o_Question.s_ExpectedAnswer"
                />
              </div>

              <v-dialog v-model="dialog" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t("general.you_will_delete_a_question") }}!
                  </v-card-title>
                  <v-card-text class="black--text"
                    >{{
                      $t("general.would_you_like_to_continue")
                    }}?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">
                      {{ $t("general.no") }}
                    </v-btn>
                    <v-btn color="red darken-1" text @click="deleteItem(index)">
                      {{ $t("general.delete") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </div>
          <!--End--Frage an den Kandidaten/an die Kandidatin:-->

          <base-hover-button
            v-if="Pi_TestTypeID && Pi_TestTypeID != 1 && Pi_TestTypeID != 3"
            class="mt-1"
            color="primary lighten-5"
            text-color="primary--text"
            :text="$t('general.new_question')"
            @click.native="addItem()"
            iconName=" mdi-plus"
          />
          <v-divider dark></v-divider>
          <div class="d-flex justify-space-between mt-5">
            <base-hover-button
              color="success lighten-5"
              :text="$t('general.save')"
              textColor="success--text"
              @click.native="save()"
              iconName="mdi-content-save"
              :loading="Lb_Saving"
            />

            <base-hover-button
              color="primary lighten-5"
              text-color="primary--text"
              :text="$t('general.back')"
              @click.native="goBack()"
              iconName=" mdi-arrow-left-circle"
            />
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="Lb_Saving"
                :disabled="Lb_Saving"
                color="success"
                class="saveButton ma-2 white--text"
                fab
                @click.native="save()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-content-save-all-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("general.save") }}</span>
          </v-tooltip>
        </v-card-text>
      </base-card>
    </v-col>
    <v-dialog v-model="Lb_ErrorDialog" persistent max-width="290">
      <v-card>
        <v-app-bar dark color="danger"
          ><v-card-title color="primary" class="text-h5">
            Opps
          </v-card-title></v-app-bar
        >

        <v-card-text class="mt-4 text-h5 black--text">{{
          $t("errormessages.the_form_must_be_complete")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="Lb_ErrorDialog = false">
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Tiptap from "../../../components/editor/Tiptap.vue";
import { post, get } from "../../../worker/worker-api";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
const notOnly = (value) => value.indexOf("<p></p>") < 0;
export default {
  mixins: [validationMixin],
  name: "NewQuestion",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.$t("general.new_question"),
    };
  },
  components: {
    Tiptap,
  },
  props: {
    Pi_TestTypeID: {
      type: Number,
      default: null,
    },
    o_Fields: {
      type: Object,
      default () {
        return {
          status: true,
          author: true,
          language: true,
          advancedModule: true,
          electiveModule: true,
          tag: true,
          designation: true,
          description: true,
          role: true,
          initialSituation: true,
          statement: true,
          solution: true,
          questions: true
        }
      },
    },
  },
  validations() {
    if (this.Pi_TestTypeID == 1) {
      return {
        Lo_Question: {
          i_Status: { required },
          s_Language: { required },
          s_Designation: { required, maxLength: maxLength(50) },
          s_Description: { required /*notOnly*/ },
          s_CandidateRole: { required /*notOnly*/ },
          s_InitialSituation: { required /*notOnly*/ },
          s_AdvancedModule: { required },
          s_ElectiveModule: { required },
          s_Tag: { required },
          a_Questions: {
            required,
            minLength: minLength(4),
            $each: {
              s_Question: {
                required,
                /*notOnly*/
              },
              s_ExpectedAnswer: {
                required,
                /*notOnly*/
              },
            },
          },
        },
        La_selectedAuthors: { required, minLength: minLength(1) },
      };
    } else {
      return {
        Lo_Question: {
          i_Status: { required },
          s_Language: { required },
          s_Designation: { required, maxLength: maxLength(50) },
          s_Description: { required /*notOnly*/ },
          s_InitialSituation: { required /*notOnly*/ },
          s_TestPresentation: { required /*notOnly*/ },
          s_SolutionPresentation: { required /*notOnly*/ },
          s_AdvancedModule: { required },
          s_ElectiveModule: { required },
          s_Tag: { required },
          a_Questions: {
            required,
            minLength: minLength(12),
            $each: {
              s_Question: {
                required,
                /*notOnly*/
              },
              s_ExpectedAnswer: {
                required,
                /*notOnly*/
              },
            },
          },
        },
        La_selectedAuthors: { required, minLength: minLength(1) },
      };
    }
  },
  computed: {
    ...mapGetters(["getProfile", "getUserID", "getRoles"]),
    NumberofMandatoryQuestions() {
      if (this.Lo_Question.i_TestTypeID && this.Lo_Question.i_TestTypeID == 1) {
        return 4;
      }
      return 12;
    },
    StatusErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.i_Status.$dirty) return errors;
      !this.$v.Lo_Question.i_Status.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    AuthorsErrors() {
      const errors = [];
      if (!this.$v.La_selectedAuthors.$dirty) return errors;
      !this.$v.La_selectedAuthors.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    LanguageErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_Language.$dirty) return errors;
      !this.$v.Lo_Question.s_Language.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    DesignationErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_Designation.$dirty) return errors;
      !this.$v.Lo_Question.s_Designation.required &&
        errors.push(this.$t("errormessages.is_required"));
      !this.$v.Lo_Question.s_Designation.maxLength &&
        errors.push(this.$t("errormessages.50_characters_maximum"));
      return errors;
    },
    AdvancedModuleErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_AdvancedModule.$dirty) return errors;
      !this.$v.Lo_Question.s_AdvancedModule.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    ElectiveModuleErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_ElectiveModule.$dirty) return errors;
      !this.$v.Lo_Question.s_ElectiveModule.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    TagErrors() {
      const errors = [];
      if (!this.$v.Lo_Question.s_Tag.$dirty) return errors;
      !this.$v.Lo_Question.s_Tag.required &&
        errors.push(this.$t("errormessages.is_required"));
      return errors;
    },
    DescriptionErrors() {
      if (!this.$v.Lo_Question.s_Description.$dirty) return false;
      if (!this.$v.Lo_Question.s_Description.required) return true;
      //if (!this.$v.Lo_Question.s_Description.notOnly) return true;
    },
    CandidateRoleErrors() {
      if (!this.$v.Lo_Question.s_CandidateRole.$dirty) return false;
      if (!this.$v.Lo_Question.s_CandidateRole.required) return true;
      //if (!this.$v.Lo_Question.s_CandidateRole.notOnly) return true;
    },
    InitialSituationErrors() {
      if (!this.$v.Lo_Question.s_InitialSituation.$dirty) return false;
      if (!this.$v.Lo_Question.s_InitialSituation.required) return true;
      //if (!this.$v.Lo_Question.s_InitialSituation.notOnly) return true;
    },
    TestPresentationErrors() {
      if (!this.$v.Lo_Question.s_TestPresentation.$dirty) return false;
      if (!this.$v.Lo_Question.s_TestPresentation.required) return true;
      //if (!this.$v.Lo_Question.s_TestPresentation.notOnly) return true;
    },
    SolutionPresentationErrors() {
      if (!this.$v.Lo_Question.s_SolutionPresentation.$dirty) return false;
      if (!this.$v.Lo_Question.s_SolutionPresentation.required) return true;
      //if (!this.$v.Lo_Question.s_SolutionPresentation.notOnly) return true;
    },
    QuestionsSizeErrors() {
      if (!this.$v.Lo_Question.a_Questions.$dirty) return false;
      if (!this.$v.Lo_Question.a_Questions.required) return true;
      if (!this.$v.Lo_Question.a_Questions.minLength) return true;
    },
    likesAllAuthor() {
      return this.La_selectedAuthors.length === this.La_Authors.length;
    },
    likesSomeAuthor() {
      return this.La_selectedAuthors.length > 0 && !this.likesAllAuthor;
    },
    icon() {
      if (this.likesAllAuthor) return "mdi-close-box";
      if (this.likesSomeAuthor) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    DesignationLength: {
      get() {
        if (
          this.Lo_Question.s_Designation != null &&
          this.Lo_Question.s_Designation.length > 51
        ) {
          let s_DesignationText = this.Lo_Question.s_Designation;
          let result = s_DesignationText.substr(0, 50);

          return result;
        } else {
          return this.Lo_Question.s_Designation;
        }
      },
      set(val) {
        this.Lo_Question.s_Designation = val;
      },
    },
  },
  watch: {
    "$i18n.locale": function () {
      if (this.Pi_TestTypeID == 1) {
        this.La_CISubQuestions = this.$t("general.a_CISubQuestions");
        this.SetCISubQuestions();
      }
    },
    La_selectedAuthors: function (val) {
      this.Lo_Question.s_AuthorsStringList = val.toString();
    },
  },
  methods: {
    getAuthors() {
      get(`${process.env.VUE_APP_SMT_API_URL}/teacher/authors`)
        .then((response) => {
          this.La_Authors = this.sortAuthors(response.a_AuthorsList);
          this.La_selectedAuthors.push(response.i_CurrentAuthAdID);
        })
        .catch((errors) => {
          console.log("submit Worker error: ", errors);
        });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllAuthor) {
          this.La_selectedAuthors = [];
        } else {
          this.La_selectedAuthors = this.La_Authors.slice();
        }
      });
    },
    sortAuthors(A_Authors) {
      const sortedlist = A_Authors.sort(function (a, b) {
        const nameA = a.s_Name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.s_Name.toUpperCase(); // ignore upper and lowercase

        // sort in an ascending order
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      return sortedlist;
    },

    goBack() {
      if (this.Pi_TestTypeID == 1) {
        this.$router.push({ name: "ci-oral-questions" });
      } else if (this.Pi_TestTypeID == 2) {
        this.$router.push({ name: "fg-oral-questions" });
      } else {
        this.$router.back();
      }
    },
    QuestionErrors(Ai_Index) {
      if (
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_Question.$dirty ||
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_ExpectedAnswer.$dirty
      )
        return false;
      if (
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_Question.required ||
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_ExpectedAnswer
          .required
      )
        return true;
      /*if (
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_Question.notOnly ||
        !this.$v.Lo_Question.a_Questions.$each[Ai_Index].s_ExpectedAnswer
          .notOnly
      )
        return true;*/
    },
    SetCIQuestionsNummber: function () {
      for (let i = 0; i < 4; i++) {
        const La_Questions = {
          s_Question: this.La_CISubQuestions[i],
          s_ExpectedAnswer: "",
        };
        this.Lo_Question.a_Questions.push(La_Questions);
      }
    },
    SetCISubQuestions: function () {
      this.Lo_Question.a_Questions.forEach((el, index) => {
        this.Lo_Question.a_Questions[index].s_Question =
          this.La_CISubQuestions[index];
      });
    },
    addItem: function () {
      this.Lo_Question.a_Questions.push({ ...this.o_newQuestion });
      this.editFragen = true;
    },
    deleteItem: function (deleteItem) {
      this.Lo_Question.a_Questions.splice(deleteItem, 1);
      this.dialog = false;
      this.$v.Lo_Question.a_Questions.$touch();
    },
    save() {
      if (this.Lo_Question.i_Status == 0) {
        this.Lb_Saving = true;
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/newquestion`, {
          o_Question: this.Lo_Question,
          i_PersonID: this.getUserID,
        })
          .then((response) => {
            this.Lb_Saving = false;
            this.$router.back();
          })
          .catch((errors) => {
            console.log("submit Worker error: ", errors);
          });
      } else {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.Lb_ErrorDialog = true;
        }
        if (!this.$v.$invalid) {
          this.Lb_Saving = true;
          post(`${process.env.VUE_APP_SMT_API_URL}/teacher/newquestion`, {
            o_Question: this.Lo_Question,
          })
            .then((response) => {
              this.Lb_Saving = false;
              this.$router.back();
            })
            .catch((errors) => {
              console.log("submit Worker error: ", errors);
            });
        }
      }
    },
    getRole() {
      let AuthorRole = null;
      let TranslatorRole = null;
      let QSKRole = null;
      let roles = this.getRoles.filter(
        (Roles) =>
          Roles.s_Role == "translator" ||
          Roles.s_Role == "author" ||
          Roles.s_Role == "qsk"
      );
      for (let x in roles) {
        if (roles[x].s_Role == "qsk") {
          QSKRole = true;
        } else if (roles[x].s_Role == "translator") {
          TranslatorRole = true;
        } else if (roles[x].s_Role == "author") {
          AuthorRole = true;
        }
      }
      if (QSKRole) {
        return (this.La_Status = this.$t("general.a_Status"));
      }
      if (AuthorRole && TranslatorRole && !QSKRole) {
        return (this.La_Status = this.$t("general.a_Status").filter(
          (status) => status.i_ID == 2 || status.i_ID == 1
        ));
      } else if (AuthorRole && !QSKRole) {
        return (this.La_Status = this.$t("general.a_Status").filter(
          (status) => status.i_ID == 0 || status.i_ID == 1
        ));
      }
    },
  },
  created() {
    this.getRole();
    this.getAuthors();
    this.Lo_Question.s_Autor =
      this.getProfile.firstname + " " + this.getProfile.lastname;
    if (this.Pi_TestTypeID) {
      this.Lo_Question.i_TestTypeID = this.Pi_TestTypeID;
      if (this.Pi_TestTypeID == 1) {
        this.SetCIQuestionsNummber();
      }
    } else {
      //go to the last page
      this.$router.back();
    }
  },
  data() {
    return {
      dialog: false,
      editDesignation: false,
      editDescription: false,
      editRolle: false,
      editAusgang: false,
      editPresentation: false,
      editSolutionPresentation: false,
      editFragen: false,
      Lb_Saving: false,
      o_newQuestion: {},
      La_Lang: ["de", "fr", "it"],
      Lo_Question: {
        s_Language: "de",
        i_TestTypeID: null,
        s_Autor: "",
        i_Status: 0,
        s_Designation: "",
        s_Description: "",
        s_CandidateRole: "",
        s_InitialSituation: "",
        s_TestPresentation: "",
        s_SolutionPresentation: "",
        s_AdvancedModule: "",
        s_ElectiveModule: "",
        s_Tag: "",
        a_Questions: [],
        s_AuthorsStringList: "",
      },
      La_CISubQuestions: this.$t("general.a_CISubQuestions"),
      Lb_ErrorDialog: false,
      La_selectedAuthors: [],
      La_Authors: [],
      La_Status: [],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: bold;
}
.saveButton {
  position: fixed;
  right: 20px;
  bottom: 85px;
  z-index: 2;
}
.v-tooltip__content {
  max-width: 20%;
}
</style>