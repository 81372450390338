<template>
  <div v-if="editor" d-flex>
    <v-card>
      <!--<v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().setParagraph().run()"
            :class="{ 'is-active': editor.isActive('paragraph') }"
          >
            <v-icon>mdi-format-paragraph</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.paragraph") }}</span>
      </v-tooltip>-->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
          >
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.bold") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
          >
            <v-icon>mdi-format-italic</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.italic") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': editor.isActive('strike') }"
          >
            <v-icon>mdi-format-strikethrough</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.strike") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().setUnderline().run()"
            :disabled="editor.isActive('underline')"
          >
            <v-icon>mdi-format-underline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.underline") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
          >
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.bullet_list") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': editor.isActive('orderedList') }"
          >
            <v-icon>mdi-format-list-numbered</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.ordered_list") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().setHardBreak().run()"
          >
            <v-icon>mdi-format-pilcrow</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.line_break") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="setLink"
            :class="{ 'is-active': editor.isActive('link') }"
          >
            <v-icon>mdi-link-variant-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.setLink") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().unsetLink().run()"
            :disabled="!editor.isActive('link')"
          >
            <v-icon>mdi-link-variant-minus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.unsetLink") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="
              editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                .run()
            "
          >
            <v-icon>mdi-table-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.add_Table") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().deleteTable().run()"
            :disabled="!editor.can().deleteTable()"
          >
            <v-icon>mdi-table-minus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.delet_Table") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().addColumnBefore().run()"
          >
            <v-icon>mdi-table-column-plus-before</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.addColumnBefore") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().addColumnAfter().run()"
          >
            <v-icon>mdi-table-column-plus-after</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.addColumnAfter") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().deleteColumn().run()"
          >
            <v-icon>mdi-table-column-remove</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.delet_Column") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().addRowBefore().run()"
          >
            <v-icon>mdi-table-row-plus-before</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.add_Row_Before") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().addRowAfter().run()"
          >
            <v-icon>mdi-table-row-plus-after</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.add_Row_After") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().deleteRow().run()"
          >
            <v-icon>mdi-table-row-remove</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.delet_Row") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="dialog = true"
          >
            <v-icon>mdi-image-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.setImage") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().undo().run()"
          >
            <v-icon>mdi-undo</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.undo") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().redo().run()"
          >
            <v-icon>mdi-redo</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("editor.redo") }}</span>
      </v-tooltip>
    </v-card>

    <v-card-text class="pa-0 mt-2">
      <editor-content :editor="editor" class="white pa-5 rounded-lg" />
    </v-card-text>

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("general.add_image") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-img :src="s_ImageToInsert" :max-width="s_ImageWidth"></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-file-input
                  :rules="rules"
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$t('general.pick_a_picture')"
                  prepend-icon="mdi-camera"
                  :label="$t('general.pick_a_picture')"
                  v-model="La_File"
                  @change="loadImage()"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-radio-group 
                  row
                  v-model="s_ImageSize"
                >
                  <v-radio
                    :label="$t('Short')"
                    value="size-image-s"
                    @click="s_ImageWidth = '33%'"
                  ></v-radio>
                  <v-radio
                    :label="$t('Medium')"
                    value="size-image-m"
                    @click="s_ImageWidth = '66%'"
                  ></v-radio>
                  <v-radio
                    :label="$t('Large')"
                    value="size-image-l"
                    @click="s_ImageWidth = '100%'"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            {{ $t("general.close") }}
          </v-btn>
          <v-btn color="success" text @click="insertImage()">
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Image from "@tiptap/extension-image";

export default {
  components: {
    EditorContent,
  },
  props: ["value"],

  data() {
    return {
      editor: null,
      dialog: false,
      La_File: null,
      Ls_ImgData: null,
      rules: [
        (value) =>
          !value || value.size < 8000000 || this.$t("errormessages.image_size"),
      ],
      s_ImageClass: 's-image-class',
      s_ImageToInsert: null,
      s_ImageSize: 'size-image-s',
      s_ImageWidth: '33%',
    };
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;
      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
      if (isSame) {
        return;
      }

      if (this.editor) this.editor.commands.setContent(value, false);
    },
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt(
        this.$t("general.please_enter_a_valid_URL_completely") +
          "\n\n https://www.diplom-asgs.ch",
        previousUrl
      );
      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    setImageSize (As_Class) {
      const La_ImageNode = this.editor.options.extensions.filter(node => node.name === 'image')
      let Lo_ImageNode = null
      if (La_ImageNode.length > 0) {
        Lo_ImageNode = La_ImageNode[0] 
      }
      Lo_ImageNode.options.HTMLAttributes.class = As_Class
      this.editor.view.state.doc.content.content[4].attrs.size = 'm'
    },
    addImage() {
      function readFileAsync(A_File) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();

          reader.onload = () => {
            resolve(reader.result);
          };

          reader.onerror = reject;

          reader.readAsDataURL(A_File);
        });
      }
      async function processFile(A_File, a_editor) {
        a_editor
          .chain()
          .focus()
          .setImage({
            src: await readFileAsync(A_File),
            alt: 'size-image-m'
          })
          .run();
      }
      processFile(this.La_File, this.editor);
    },
    readFileAsyncImg(A_File) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(A_File);
      });
    },
    async loadImage() {
      this.s_ImageToInsert = await this.readFileAsyncImg(this.La_File)
    },
    insertImage() {
      var r = document.querySelector(':root')
      var rs = getComputedStyle(r);
      let Ls_Width
      if (this.s_ImageSize === 'size-image-s') Ls_Width = rs.getPropertyValue('--var-image-width-s')
      if (this.s_ImageSize === 'size-image-m') Ls_Width = rs.getPropertyValue('--var-image-width-m')
      if (this.s_ImageSize === 'size-image-l') Ls_Width = rs.getPropertyValue('--var-image-width-l')
      r.style.setProperty('--var-image-width', Ls_Width)
      this.editor
        .chain()
        .focus()
        .setImage({
          src: this.s_ImageToInsert,
          alt: this.s_ImageSize
        })
        .run();
      this.dialog = false
      this.updateClassStyleImage()
    },
    updateClassStyleImage () {
      let Ls_ImageSize = 'size-image-s'
      let imgElement = document.querySelectorAll('img[alt="size-image-s"]')
      if (this.s_ImageSize === 'size-image-m') {
        imgElement = document.querySelectorAll('img[alt="size-image-m"]')
        Ls_ImageSize = 'size-image-m'
      }
      if (this.s_ImageSize === 'size-image-l') {
        imgElement = document.querySelectorAll('img[alt="size-image-l"]')
        Ls_ImageSize = 'size-image-l'
      }
      const La_Images = Array.from(imgElement);
      La_Images.forEach(image => {
        image.className = ''
        image.classList.add(Ls_ImageSize)
      })
    },
    updateClassSizeImage () {
      let La_ImageSize = ['size-image-s', 'size-image-m', 'size-image-l']
      La_ImageSize.forEach(imgSize => {
        let imgElement = document.querySelectorAll(`img[alt="${imgSize}"]`)
        const La_Images = Array.from(imgElement);
        La_Images.forEach(image => {
          image.className = ''
          image.classList.add(imgSize)
        })
      })
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false,
        }),
        Table.configure({
          resizable: true,
          HTMLAttributes: {
            class: "custom-table-class",
          },
        }),
        TableRow,
        TableHeader,
        TableCell,
        Image.configure({
          allowBase64: true,
          HTMLAttributes: {
            class: "custom-image-class",
          },
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());
        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    });
    this.updateClassStyleImage('Tiptap mounted')
  },
  updated() {
    this.updateClassSizeImage()
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
* {
    --var-image-width: 50%;
    --var-image-width-s: 33%;
    --var-image-width-m: 66%;
    --var-image-width-l: 100%;
  }

.custom-image-class {
  max-width: var(--var-image-width);
  height: auto;
}

.size-image-s {
  max-width: var(--var-image-width-s) !important;
  height: auto;
}

.size-image-m {
  max-width: var(--var-image-width-m) !important;
  height: auto;
}

.size-image-l {
  max-width: var(--var-image-width-l) !important;
  height: auto;
}
/* custom-table-class on view */
.custom-table-class {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;

  td,
  th {
    min-width: 1em;
    border: 2px solid #ced4da;
    padding: 12px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;

    > * {
      margin-bottom: 0;
    }
  }

  th {
    font-weight: bold;
    text-align: left;
    background-color: #f1f3f5;
  }

  .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
  }

  .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: -2px;
    width: 4px;
    background-color: #adf;
    pointer-events: none;
  }

  p {
    margin: 0;
    font-weight: unset;
  }
}

p {
  margin: 0;
  font-weight: unset;
}
/* custom-table-class in Edit */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
      font-weight: unset;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

/* Basic editor styles */
.ProseMirror {
  padding: inherit;
}
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 50%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  a {
    color: #68cef8;
  }
  p {
    font-weight: unset;
  }
}
</style>